<template>
      <el-menu
        :default-active="nowPath"
        class="el-menu-vertical-demo"
        router
        unique-opened
        active-text-color="#fe4800"
        background-color="#fff"
         @open="handleOpen"
         @close="handleClose"
      >
        <div v-for="(item) in menu" :key="item" class="elMenu">
          <el-menu-item :index="item.path" @click="pathItem(item)" v-if="!item.children">{{item.title}}</el-menu-item>
          <el-sub-menu :index="item.title" style="width: 200px;" v-if="item.children">
            <template #title>
              <el-icon><icon-menu /></el-icon>
              <span>{{item.title}}</span>
            </template>
            <el-menu-item-group v-for="itemc in item.children" :key="itemc">
              <el-menu-item :index="itemc.path" @click="pathItem(itemc)">{{itemc.title}}</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
        </div>
        
      </el-menu>
</template>

<script>
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'
import {reactive, ref} from 'vue'
import { useRouter } from 'vue-router'
export default {
  components: { Document, Location, Setting, IconMenu },
  setup() {
    const $route = useRouter()
    const menu = reactive([
      {
        path: '/aa',
        title: '主页',
      },
        {
            path: '/wei1',
            title: '历史记录',
        }
    ])
    const nowPath = ref('/aa')
    const handleOpen = function(key, keyPath){
      console.log('key：',key)
      if(key == '首页' && nowPath.value === '/aa') {
        $route.replace('/aa')
      }
    }
    const handleClose = function(key, keyPath) {
      console.log('key22',key)
      console.log('keyPath22', keyPath)
    }
    const pathItem = item => {
      nowPath.value = item.path
    }
    
    return {handleOpen, handleClose, menu,pathItem, nowPath}
  }
}
</script>

<style>
</style>