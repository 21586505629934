<template>
  <div>
    <el-container style="height: 100vh;">
      <el-header class="head"><Top/></el-header>
      <el-container>
        <el-aside width="200px" class="left"><LeftNav/></el-aside>
        <el-main class="right"><router-view/></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Top from '@/views/home/top'
import LeftNav from '@/views/home/leftnav'
export default {
  components: {Top, LeftNav},
  methods:{

  },
  data(){
    return {

    };
  },
  mounted(){
  },
}
</script>

<style>
.head {background: #ccc;}
.left {background: #ddd;}
.right {background: #eee;}
</style>
